<template>
  <v-app>
    <v-navigation-drawer
      v-if="$root.userId"
      v-model="drawer"
      app
      clipped
    >
      <v-list dense nav>
        <v-list-item to="/events" exact>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Übersicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-if="$root.locationEvent">
        <v-divider/>
        <v-list dense nav>
          <div v-if="$root.locationEvent.event.logo?.src" class="ma-2 pb-2">
            <v-img
              :src="getFileURL($root.locationEvent.event.logo.src)"
              contain
            />
          </div>
          <v-subheader v-else :style="$root.locationEvent.event._deleted ? 'text-decoration: line-through;' : ''">{{ $root.locationEvent.event.name }}</v-subheader>
          <v-list-item v-for="menu in eventMenu" :key="menu.id" :to="menu.to" exact>
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <template v-slot:append>
        <template v-if="$root.locationEvent && ($root.user?.admin || $root.user?.permSettings)">
          <v-divider/>
          <v-list dense nav>
            <v-subheader>{{ $root.locationEvent.location.name }}</v-subheader>
            <v-list-item :to="'/locations/' + $root.locationEvent.location.id + '/events/' + $root.locationEvent.event.id + '/settings'">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Einstellungen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template v-if="$root.user?.admin">
          <v-divider/>
          <v-list dense nav>
            <v-subheader>ADMIN</v-subheader>
            <v-list-item to="/locations" exact>
              <v-list-item-icon>
                <v-icon>mdi-map-marker-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Standorte</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/users">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Benutzer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <v-divider/>
        <v-list dense nav>
          <v-list-item @click="$root.signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Abmelden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="drawerRight"
      app
      right
      clipped
      disable-resize-watcher
      width="360"
    >
      <iframe
        v-if="drawerRight"
        src="https://docs.google.com/document/d/e/2PACX-1vTNK2Fcz2QlEI4YszZRKHm9XZ_-e3g56UOXHuPDZ0539i1pgzDAcQzFzX5xcnhYWU1Z2kFZbvOCZTpY/pub?embedded=true"
        style="display: block; width: 100%; height: 100%; overflow-y: auto;"
        frameborder="0"
      />
    </v-navigation-drawer>

    <v-app-bar
      v-if="$root.userId"
      app
      color="primary"
      dark
      clipped-left
      clipped-right
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-menu max-height="100%" offset-y style="height: 100%; overflow-y: hidden;">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            width="360"
            class="menu-btn"
          >
            <span>{{ $route.name === 'Events' ? 'ÜBERSICHT' : $route.name === 'Archive' ? 'ARCHIV' : $root.locationEvent?.event.name }}</span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded class="pa-4" style="max-height: 100%; overflow-y: auto;">
          <!-- <v-list-item-group v-model="$root.eventId"> -->
            <div v-for="location in $root.locationsEvents" :key="location.id">
              <v-list-item-subtitle>{{ location.name }}</v-list-item-subtitle>
              <!-- <v-list-item v-for="event in location.events" :key="event.id" :value="event.id"> -->
              <v-list-item v-for="event in location.events.filter(e => !e._deleted)" :key="event.id" :to="'/locations/' + location.id + '/events/' + event.id">
                <v-list-item-content>
                  <v-list-item-title>{{ event.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mb-3"/>
            </div>
          <!-- </v-list-item-group> -->
          <v-list-item to="/events" exact>
            <v-list-item-content>
              <v-list-item-title>ÜBERSICHT</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/archive" exact>
            <v-list-item-content>
              <v-list-item-title>ARCHIV</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer/>
      <div class="pa-3" style="height: 100%;">
        <v-img
          v-if="$root.user?.logo?.src"
          :src="getFileURL($root.user?.logo.src)"
          contain
          height="100%"
          class="mx-auto"
          style="max-height: 100%;"
        />
      </div>
      <v-toolbar-title v-if="!live" class="mx-4"># TESTUMGEBUNG #</v-toolbar-title>
      <v-spacer/>
      <span class="text-right" style="width: 360px">{{ $root.user?.name || $root.user?.email }}</span>
      <v-icon class="ml-3">mdi-account-circle</v-icon>
      <!-- <v-btn icon @click.stop="drawerRight = !drawerRight" class="ml-5">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-snackbar v-model="showSnack" top :color="snack ? snack.color : null">
      {{ snack ? snack.text : null }}
    </v-snackbar>

    <v-main>
      <router-view v-if="$root.userId && $root.locationsEvents"/>
      <sign-in v-else-if="$root.userId === false"/>
    </v-main>
  </v-app>
</template>

<script>
import { LIVE } from '@/plugins/firebase'
import FileMixin from '@/mixins/FileMixin.js'
import SignIn from '@/views/SignIn'
import { EVENT_VIEWS } from '@/constants'

export default {
  name: 'App',
  mixins: [FileMixin],
  components: { SignIn },
  data () {
    return {
      drawer: null,
      drawerRight: false,
      showSnack: false
    }
  },
  computed: {
    live () {
      return LIVE
    },
    snack () {
      return this.$root.snack
    },
    eventMenu () {
      if (!this.$root.locationEvent) return null
      return EVENT_VIEWS.map(v => ({
        ...v,
        to: '/locations/' + this.$root.locationId + '/events/' + this.$root.eventId + '?view=' + v.id
      }))
    }
  },
  watch: {
    snack (snack) {
      if (snack) {
        this.showSnack = true
      }
    },
    showSnack (showSnack) {
      if (!showSnack) {
        this.$root.snack = null
      }
    }
  }
}
</script>

<style lang="css" scoped>
.menu-btn >>> .v-btn__content {
  justify-content: space-between;
}
</style>