export const LANGS = ['de', 'fr', 'en']

export const STATUS_FIELDS = ['erfasst', 'geprueft', 'produziert', 'montiert', 'abgebaut', 'eingelagert'] // 1, 2, 3, 4, 5, 6
export const STATUS_LABELS = ['erfasst', 'geprüft', 'produziert', 'montiert', 'abgebaut', 'eingelagert'] // 1, 2, 3, 4, 5, 6
export const STATUS_LABELS_UC = STATUS_LABELS.map(l => l[0].toUpperCase() + l.substring(1))

export const EVENT_VIEWS = [
  { id: 'event', name: 'Veranstaltung', icon: 'mdi-calendar-month-outline' },
  { id: 'areal', name: 'Areal', icon: 'mdi-map-outline' },
  { id: 'hallen', name: 'Hallen', icon: 'mdi-domain' },
  { id: 'aussteller', name: 'Aussteller', icon: 'mdi-clipboard-account-outline' },
  { id: 'themes', name: 'Themen', icon: 'mdi-image-filter-center-focus-strong-outline' },
  { id: 'icons', name: 'Icons', icon: 'mdi-star-outline' },
  { id: 'iconfiles', name: 'Iconfiles', icon: 'mdi-file-star-outline' },
  { id: 'texts', name: 'Texte', icon: 'mdi-text-recognition' },
  { id: 'standnummern', name: 'Standnummern', icon: 'mdi-counter' },
  { id: 'werbetechnik', name: 'Werbetechnik', icon: 'mdi-routes' },
  { id: 'export', name: 'Export', icon: 'mdi-file-export-outline' },
]
