import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/events'
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "events" */ './views/Events.vue')
  },
  {
    path: '/locations/:locationId/events/:eventId',
    name: 'Event',
    component: () => import(/* webpackChunkName: "event" */ './views/Event.vue')
  },
  {
    path: '/locations/:locationId/events/:eventId/settings',
    name: 'Settings',
    meta: { title: 'Einstellungen' },
    component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue')
  },
  {
    path: '/archive',
    name: 'Archive',
    meta: { title: 'Archiv' },
    component: () => import(/* webpackChunkName: "archive" */ './views/Events.vue')
  },
  {
    path: '/locations',
    name: 'Locations',
    meta: { title: 'Administration - Standorte' },
    component: () => import(/* webpackChunkName: "locations" */ './views/Locations.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: { title: 'Administration - Benutzer' },
    component: () => import(/* webpackChunkName: "users" */ './views/Users.vue')
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "signin" */ './views/SignIn.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
