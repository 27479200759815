import { render, staticRenderFns } from "./App.vue?vue&type=template&id=b08fc294&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=b08fc294&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.18_css-loader@6.10.0_webpack@5.90.1__lodash@4.17.21__r5uqfak4p3rnbgnjc6ijs2se7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b08fc294",
  null
  
)

export default component.exports